import store from 'store';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFirstEntry, updateIsFirstEntry } from 'containers/App/slices';

const useFirstEntry = () => {
  const dispatch = useDispatch();
  const isFirstEntry = useSelector(selectIsFirstEntry);

  const setEntryTime = () => {
    const now = dayjs();
    const endTime = now.endOf('day');
    const duration = new Date().getTime() + endTime.diff(now);
    store.set('is_first_entry', false, duration);
    dispatch(updateIsFirstEntry(false));
  };

  const initIsFirstEntry = value => {
    const isFirst = store.get('is_first_entry');
    dispatch(updateIsFirstEntry(typeof isFirst === 'undefined' ? true : Boolean(+value)));
  };

  return {
    isFirstEntry,
    setEntryTime,
    initIsFirstEntry,
  };
};

export default useFirstEntry;
