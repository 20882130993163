import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import qs from 'qs';
import { useGetCms } from 'containers/CustomerService/queries';
import useGetVendorCode from 'utils/Hooks/useGetVendorCode';
import Buttons from 'components/Buttons/Buttons';
import FormatMessage from 'components/FormatMessage';
import { determineWeChatEnvironment } from 'utils/WeChat/determineEnvironment';
import useFirstEntry from 'containers/App/hooks/useFirstEntry';
import messages from '../messages';

export default function FirstEntry() {
  const vendorCode = useGetVendorCode();
  const { data } = useGetCms(vendorCode ? `${vendorCode}-PrivacyPolicy` : '');
  const { isFirstEntry, setEntryTime } = useFirstEntry();

  if (!determineWeChatEnvironment || !isFirstEntry) {
    return null;
  }

  const handleReject = () => {
    if (determineWeChatEnvironment) {
      wx.miniProgram.navigateTo({
        url: `/pages/hybrid/hybrid?${qs.stringify({
          resource: 'exitMiniProgram',
        })}`,
      });
    }
  };

  return (
    <Drawer anchor="top" open={isFirstEntry}>
      <Box height="100vh" display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center" py={2}>
          <Typography variant="h2">{data?.title || ''}</Typography>
        </Box>
        <Box
          dangerouslySetInnerHTML={{ __html: data?.content || '' }}
          px={2}
          flex={1}
          overflow="auto"
        />
        <Box p={2}>
          <Buttons color="primary" variant="contained" onClick={setEntryTime}>
            <FormatMessage message={messages.agreeEntry} />
          </Buttons>
          <Buttons fullWidth onClick={handleReject}>
            <FormatMessage message={messages.rejectEntry} />
          </Buttons>
        </Box>
      </Box>
    </Drawer>
  );
}
