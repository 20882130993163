import { useState, useEffect } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usePostLogin from 'utils/Hooks/usePostLogin';
import useInitApplication from 'utils/Hooks/useInitApplication';
import { updateWechatParams } from 'containers/App/slices';
import useFirstEntry from 'containers/App/hooks/useFirstEntry';

const useWechatToWebview = () => {
  const dispatch = useDispatch();
  const postLogin = usePostLogin();
  const initApp = useInitApplication();
  const { initIsFirstEntry } = useFirstEntry();
  const { location: { hash } = {} } = useHistory();

  const [params, setParams] = useState({});

  const setHashParams = values => {
    if (!values) {
      return;
    }

    const { source, token, openid, isFirstEntry } = values;

    initIsFirstEntry(isFirstEntry);

    if (source !== 'weapp') {
      return;
    }

    setParams(values);
    dispatch(updateWechatParams(values));

    if (openid) {
      localStorage.setItem('openid', openid);
    }
    if (token) {
      postLogin(token).then(() => initApp());
    }
  };

  useEffect(() => {
    function handleHashChange() {
      try {
        const values = qs.parse(window.location.hash.substring(1));

        setHashParams(values);
      } catch (err) {
        console.log(err);
      }
    }

    window.addEventListener('hashchange', handleHashChange);

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  useEffect(() => {
    if (!hash) {
      return;
    }
    const values = qs.parse(hash.substring(1));
    setHashParams(values);
    console.log(values, 'hash');
  }, [hash]);

  return params;
};

export default useWechatToWebview;
